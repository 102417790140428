import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import useWindowDimensions from '../../Helpers/useWindowDimensions';

const Expectations = ({data , title}) => {
    const { windowWidth } = useWindowDimensions();


    return (
        <div className='expectations'>
            <h2>{title}</h2>

            {windowWidth > 900 ? <div className='container-items'>

                {
                    data?.map((item, index) => (
                        <div className='sub-sections' key={index}>
                            <p>   {item}</p>
                        </div>
                    ))
                }
            </div>
                :

                <Swiper
                    modules={[Pagination]}
                    slidesPerView={1}
                    spaceBetween={10}
                    grabCursor={true}
                    pagination={{
                        clickable: true,
                    }}

                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        940: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1200: {
                            slidesPerView: 5,
                            spaceBetween: 40,
                        },
                        1366: {
                            slidesPerView: 6,
                            spaceBetween: 40,
                        },
                    }}
                    className="container-items"
                >
                    {
                        data?.map((item, index) => (

                            <SwiperSlide key={index} className='sub-sections'>
                                <p>   {item}</p>

                            </SwiperSlide>
                        ))
                    }

                </Swiper>

            }


            <div className='links'>
                <Link to={'/guitar-lessons'} className='explore'>Explore Our Courses</Link>
                <Link to={'/membership'} className='membership'>Explore Membership</Link>



            </div>

        </div>
    )
}

export default Expectations