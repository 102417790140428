import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

const InstructorSection = ({ instructors }) => {

    return (
        <div className="instructors">
            <h6>Learn from our experts</h6>
            <div className="cards">
                <div className="arrow">
                    <div className="arr left">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                        >
                            <path
                                d="M7.28998 0.904148L1.39928 6.79485L7.28998 12.6855"
                                stroke="#222222"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M2.00763 6.66992H14.6009"
                                stroke="#222222"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div className="instructor-slider overflow-hidden"
                >
                    <Swiper
                        className='card-in swiper-wrapper'
                        slidesPerView={1}
                        spaceBetween={10}
                        slidesPerGroup={1}
                        slidesPerGroupAuto={true}
                        loop={true}
                        navigation={{
                            nextEl: '.right',
                            prevEl: '.left',
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,

                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 40,

                            },
                            1124: {
                                slidesPerView: 6,
                                spaceBetween: 50,
                                slidesPerGroup: 1,
                                loop: true,

                            },
                        }}
                        modules={[Navigation]}
                    >
                        {instructors?.slice(0, 14)?.map((instructor, index) => (
                            <SwiperSlide key={index} className="user swiper-slide">
                                <div className="prof">
                                    <Link to={`/instructors/${instructor?.hash}`}>
                                        <img loading="lazy"
                                            src={instructor?.poster}
                                            alt="inst"
                                        />
                                    </Link>
                                </div>
                                <Link to={`/instructors/${instructor?.hash}`}><p>{instructor?.name}</p></Link>


                            </SwiperSlide>
                        ))
                        }

                    </Swiper>

                </div>


                <div className="arrow">
                    <div className="arr right">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                        >
                            <path
                                d="M8.71002 12.6857L14.6007 6.795L8.71002 0.904297"
                                stroke="#222222"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.9924 6.91992L1.39913 6.91992"
                                stroke="#222222"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <Link className='btn-instru' to={'/instructors'}>View Our Instructors</Link>
        </div>


    );
};

export default InstructorSection;
