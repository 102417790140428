import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link, useLocation } from 'react-router-dom';
import useWindowDimensions from '../../../Helpers/useWindowDimensions';
import { RWebShare } from 'react-web-share';
import ConvertDurationFormat from "../../../Utility/CovertDurationFormat";
import { instructors } from '../../../Action/instructor.action';
import { useDispatch, useSelector } from 'react-redux';

const RecommendedCourse = (recomendedData) => {
    const dispatch = useDispatch();
    const { windowWidth } = useWindowDimensions();
    const authState = useSelector((state) => state.auth);
    const socialToken = authState?.userDetails?.token;
    const token = authState.userDetails.hash;
    const streakCount = useSelector((state) => state.auth.streak);
    const id = streakCount?.user_data?.liked_songs_id


    const truncate = (content) => {
        const maxwidth = windowWidth >= 910 ? 190 : 170
        const truncatedContent =
            content &&
                content?.length > maxwidth
                ? `${content.slice(0, maxwidth)}...`
                : content;
        return truncatedContent
    }


    const handleLikeSongs = (e, songsId) => {
        const liked = document
            .getElementById("song-" + songsId)
            .getAttribute("data-liked");

        if (liked === "false") {
            dispatch(
                instructors.likeSongs(
                    { id: id, lesson_id: songsId },
                    { Token: token || socialToken }
                )
            );
            e.target.closest("img").src =
                "/assets/img/love.svg";
        } else {
            dispatch(
                instructors.removeSongs(
                    { playlist_id: id, lesson_id: songsId },
                    { Token: token || socialToken }
                )
            );
            e.target.closest("img").src = "/assets/img/courses/heart.svg";
        }
        document
            .getElementById("song-" + songsId)
            .setAttribute("data-liked", liked === "false" ? true : false);
    };

    const renderCourseCard = (data) => (
        <div className="d-flex bg-white bg-white d-block d-lg-flex d-md-flex course-container cancel-section-history course-history justify-content-between my-3 align-items-start">
            <div className="d-flex align-items-start container gap-3">
                <div className="container-course-img position-relative">
                    <div className="image-container container-course-img">
                        <Link to={`/${data?.path[1]?.hash}/${data?.hash}-${data?.id}`}>
                            <img loading="lazy"className="video" src={data?.poster} alt="img" />
                            <img loading="lazy"className="play-icon" src="/assets/img/playicon.svg" alt="img" />
                        </Link>
                    </div>
                    {/* {data?.difficulty && <p class="easy">{data?.difficulty.charAt(0).toUpperCase() + data?.difficulty.slice(1)}</p>} */}
                </div>
                <div className="second-card w-100 d-flex flex-column gap-3 mt-2">
                    <div className="d-flex align-items-start gap-3 justify-content-between">
                        <div className="position-relative">
                            <Link to={`/${data?.path[1]?.hash}/${data?.hash}-${data?.id}`}><h6 className="cut-text">{data?.title}</h6></Link>
                            <p className="my-2">{truncate(data?.content)}</p>
                            {data?.instructor?.poster &&
                                <div className="d-flex align-items-center mt-3">
                                    <p>Taught by</p>
                                    <div className="images-container">
                                        <img loading="lazy"className="image" src={data?.instructor?.poster} alt="" style={{ left: '10px', top: '-10px', borderRadius: '19px', position: 'absolute' }} />
                                    </div>
                                </div>}
                        </div>
                        <div className="d-flex justify-content-end gap-2">
                            <img
                                className="love-icon"
                                src={data?.liked ? "/assets/img/love.svg" : "/assets/img/unlike.svg"}
                                alt="unlove"
                                onClick={(e) => { handleLikeSongs(e, data?.id) }}
                                id={`song-${data?.id}`}
                                data-liked={data?.liked}
                                style={{
                                    cursor: 'pointer', width: '25px', height: '30px'
                                }}
                            />
                            <RWebShare
                                style={{
                                    cursor: 'pointer'
                                }}
                                data={{
                                    text: "",
                                    url:
                                        process.env.REACT_APP_URL +
                                        `/${data?.path[1]?.hash}/${data?.hash}-${data?.id}`,
                                }}

                                onClick={() =>
                                    console.log("shared successfully!")
                                }
                            >
                                <img loading="lazy"class="share-icon" src="/assets/img/learning/share.svg" alt="share" />
                            </RWebShare>
                        </div>
                    </div>
                    <div className="d-flex md-align-items-center justify-content-between sm-flex-column mt-2">
                        <div className="d-flex mr-3 gap-1 align-items-center">
                            {data?.duration > '00:00:00' &&
                                <>
                                    <img loading="lazy"className="share-icon" src="/assets/img/clock.svg" alt="clock" />
                                    <p className="fw-bold">{ConvertDurationFormat(data?.duration)}</p>
                                </>
                            }
                            {data?.total > 0 &&
                                <>
                                    |
                                    <img loading="lazy"className="share-icon" src="/assets/img/Learnings/book.svg" alt="book" />
                                    <p className="fw-bold">{`${data?.total} Lessons`}</p>
                                </>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="cancel-course-content-second my-4 flex-column">
            <h3>However, there is so much more you can still learn....</h3>
            <Swiper
                modules={[Pagination, Navigation]}
                slidesPerView={2}
                spaceBetween={10}
                grabCursor={true}
                loop={true}
                pagination={{ clickable: true }}
                navigation={{ nextEl: '.button-next-two', prevEl: '.button-prev-two' }}
                breakpoints={{
                    320: { slidesPerView: 1, spaceBetween: 20 },
                    480: { slidesPerView: 1, spaceBetween: 30 },
                    640: { slidesPerView: 1, spaceBetween: 40 },
                    1200: { slidesPerView: 3, spaceBetween: 40 },
                }}
                className="carousel-second carousel"
            >
                {recomendedData?.recomendedData?.slice(0, 8).map((data, index) => (
                    <SwiperSlide key={index}>{renderCourseCard(data)}</SwiperSlide>
                ))}

                <div className="button-prev-two">
                    <img loading="lazy"src="/assets/img/learning/left-arrow.svg" alt="Previous" />
                </div>
                <div className="button-next-two ">
                    <img loading="lazy"src="/assets/img/learning/right-arrow.svg" alt="Next" />
                </div>
            </Swiper>
        </div>
    );
};

export default RecommendedCourse;
