import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link , useLocation} from "react-router-dom";

//components
import SideBar from "../../components/Common/SideBar";
import "../../components/Common/common.scss";
import "../SavedCourses/savedCourses.scss";
import "../Dashboard/dashboard.scss";
import "./savedNotes.scss";
import { notes } from "../../Action/note.actions";
import { loop } from "../../Action/savedLoop.actions";
import SavedNote from "./SavedNote";
import SavedLoops from "./SavedLoops";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import DeleteModal from "./DeleteModal";
import DeleteSuccesful from "./DeleteSuccesful";
import BreadCrumbs from "../../components/Common/BreadCrumbs";
import breadcrumbs from "../../components/BreadCrumbs/breadcrumbsObj";

const SavedNotes = () => {
    const dispatch = useDispatch();
    const currentUrl = useLocation()
    const [activeTab, setActiveTab] = useState("notes");
    const authState = useSelector((state) => state.auth);
    const token = authState?.userDetails?.hash;
    const SocialToken = authState?.userDetails?.token;
    const savedNoteData = useSelector((state) =>  state.note.noteSavedLoadMoreData);
    const SavedLoopLoadMoreDataAllUser = useSelector((state) =>  state.saveLoop.SavedLoopLoadMoreDataAllUser);
    const loopLoading = useSelector((state) =>  state.saveLoop.loopLoading);
    const loading = useSelector((state) =>  state.note.loading);
    const savedNotes = useSelector((state) =>  state.note.savedNotes);
    const savedLoopAllUser = useSelector((state) =>  state.saveLoop.savedLoopAllUser);
    const [component, setComponent] =  useState(false)
    const [deleteId, setDeleteId] =  useState('')
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        dispatch(
            notes.getSavedNotes('',{
                Token: token || SocialToken,
            })
        );
    }, [dispatch, token, SocialToken]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === "notes") {
            dispatch({type:'NOTE_DATA'})
            dispatch(
                notes.getSavedNotes('',{
                    Token: token || SocialToken,
                })
            );
        } else if (tab === "loops") {
            dispatch({type:'SAVED_LOOP'})
            dispatch(
                loop.getSavedLoopDataAllUser('',{
                    Token: token || SocialToken,
                })
            );
        }
    };

    const handleDelete = (componentValue, id) => {
        setDeleteId(id)
        setComponent(componentValue)
    }
    
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const sidebarClass = isHovered ? "col-md-3" : "col-md-1";
    const sidebarViewClass = isHovered ? "9" : "11";
    
    return (
        <>
            <div className="saved-notes authenticate my-dashboard " style={{ overflowX: "hidden" }}>
                <BreadCrumbs data={breadcrumbs.savedNotes} currentPage={currentUrl.pathname} />
                <div className="container-fluid h-100">
               
                    <div className="row h-100 px-0">
                   
                        <SideBar 
                            handleMouseEnter={handleMouseEnter}
                            handleMouseLeave={handleMouseLeave}
                            sidebarClass={sidebarClass}
                            isHovered={isHovered}
                        />
                        <div className={`col-xxl-${sidebarViewClass} col-xl-${sidebarViewClass} col-lg-${sidebarViewClass} col-md-${sidebarViewClass} col-sm-12 right-section`}>
                            <div
                                className="arrow-section-mob justify-content-start mx-3 my-3 align-items-center container"
                                style={{ padding: "0px 23px" }}
                            >
                                <Link to="javascript:history.back()" style={{marginLeft:'-17px'}}>
                                    <img loading="lazy"src="./assets/img/back-arrow.png" alt="Back Arrow" />
                                </Link>
                                <p className="p-0 m-0 mx-3 mob-arrow-head">
                                    Saved notes & loops
                                </p>
                            </div>
                            <div className="coursehistory-sub-head d-flex my-3 flex-xxl-nowrap tabs" style={{marginLeft:'20px'}}>
                                <div
                                    className={`course mx-2 ${activeTab === "notes" ? "active-history" : ""}`}
                                    onClick={() => handleTabClick("notes")}
                                >
                                    Saved Notes
                                </div>
                                <div
                                    className={`song-lesson ${activeTab === "loops" ? "active-history" : ""}`}
                                    onClick={() => handleTabClick("loops")}
                                >
                                    Saved Loops
                                </div>
                            </div>

                            {/* notes section */}
                            {activeTab === "notes" && (
                                loading && !savedNoteData?.length ? (
                                    <LoadingSpinner />
                                ) :
                                <SavedNote
                                    savedNoteData={savedNoteData}
                                    token={token}
                                    SocialToken={SocialToken}
                                    loading={loading}
                                    savedNotes={savedNotes}
                                    handleDelete={handleDelete}
                                />
                                
                            )}
                            
                            {/* loop section */}
                            {activeTab === "loops" && (
                                loopLoading && !SavedLoopLoadMoreDataAllUser?.length ? (
                                    <LoadingSpinner />
                                ) :
                                <SavedLoops
                                    SavedLoopLoadMoreDataAllUser={SavedLoopLoadMoreDataAllUser}
                                    token={token}
                                    SocialToken={SocialToken}
                                    savedLoopAllUser={savedLoopAllUser}
                                    handleDelete={handleDelete}
                                />
                            )}
                            
                        </div>
                    </div>
                </div>
                <DeleteModal
                    component={component} 
                    deleteId={deleteId}
                    token={token}
                    SocialToken={SocialToken}
                />
                <DeleteSuccesful
                    component={component} 
                    deleteId={deleteId}
                    token={token}
                    SocialToken={SocialToken}
                />
            </div>
          
        </>
    );
};

export default SavedNotes;
