import React, { useEffect, useRef, useState } from 'react'
import './Premium.scss'
import Expectations from './Expectations';
import { authService } from '../../Adapters/auth.services';
import { useDispatch, useSelector } from 'react-redux';
import PremiumContents from './PremiumContents';
import { instructors } from '../../Action/instructor.action';
import InstructorsPremium from './InstructorsPremium';
import MembershipPremium from './MembershipPremium';
import { membership } from '../../Action/membership.action';
import MembershipFaq from '../Membership/MembershipFaq';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../Action/auth.actions';
import { authConstants } from '../../Constants/Auth';

const Premium = () => {

    const dispatch = useDispatch()
    const videoRef = useRef(null);
    const params = new URLSearchParams(window.location.search);
    const urlToken = params.get('token');
    const navigate = useNavigate();


    const instructorData = useSelector((state) => state.instructor.instructorDetails?.data?.instructors)
    const instructorDetails = instructorData?.guitar?.concat(instructorData?.piano)?.concat(instructorData?.bass_guitar);
    const courses = useSelector((state) => state.lesson?.mainInstruments);
    const menus = courses?.courses;
    const memberships = useSelector((state) => state.member)
    const authState = useSelector((state) => state.auth)
    const token = authState?.userDetails?.hash ?? authState?.userDetails?.token;
    const instruments = useSelector((state) => state.member.instruments);

    const [playingVideo, setPlayingVideo] = useState(false);
    const [songsData, setSongsData] = useState('');
    const [coursesData, setCoursesData] = useState('');
    const [premiumData, setPremiumData] = useState([]);

    const [currentTab, setCurrentTab] = useState(0)
    const [songsHash, setSongsHash] = useState('')
    const [courseHash, setCourseHash] = useState('')
    const [selectedOption, setSelectedOption] = useState("all");
    const [isLoading, setIsLoading] = useState(false)

    const validityDate = authState?.userProfile?.user_data?.purchased_plan?.validity_date


    const formattedDate = new Date(validityDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });


    useEffect(() => {
        window.scrollTo(0, 0);
        getPremiumData()
        dispatch(instructors.getInstructors())

    }, [])


    useEffect(() => {
        if (urlToken) {
            onSubmit()
        }

    }, [urlToken])

    const onSubmit = () => {
        let requestData;
        if (urlToken) {
            requestData = {
                token: urlToken

            }

            dispatch({ type: authConstants.SIGNIN_REQUEST });
            dispatch(auth.signIn(requestData));


        }


    }




    useEffect(() => {

        if (token && authState?.userDetails?.membership_details?.membership && urlToken) {
            navigate("/dashboard");
            params?.delete('token');
            navigate({
                pathname: window.location?.pathname,
                search: params?.toString(),
            });
        } else if (urlToken) {
            params?.delete('token');
            navigate({
                pathname: window.location?.pathname,
                search: params?.toString(),
            });
        }

    }, [token, authState, urlToken]);


    useEffect(() => {
        if (menus) {
            if (menus?.courses?.length > 0) {

                const filteredDataId = menus?.courses[currentTab]?.lessons?.filter((item) => item?.free === 1)[0]?.id
                setCourseHash(`/free-${menus?.courses[currentTab]?.hash}`)
                getCourseLessonsData(filteredDataId)

            }

            if (menus?.courses?.length > 0) {
                const filteredDataId = menus?.courses[currentTab]?.lessons?.filter((item) => item?.type === 'songs')[0]?.id
                getSongsData(filteredDataId)
                setSongsHash(`/free-${menus?.courses[currentTab]?.hash}/${menus?.courses[currentTab]?.lessons?.filter((item) => item?.type === 'songs')[0]?.hash}-${filteredDataId}`)
            }
        }

    }, [currentTab])

    const handlePlayVideo = () => {
        if (videoRef?.current) {
            videoRef?.current?.play();
            setPlayingVideo(true)
        }
    };

    const pauseVideo = () => {
        if (videoRef?.current) {
            videoRef.current.pause();
            setPlayingVideo(false);
        }
    };

    const handleVideoEnd = () => {
        setPlayingVideo(false);
    };

    const filteredMemberships = Array.isArray(memberships?.userMembership) ? memberships.userMembership.filter((plan) =>
        selectedOption === "single"
            ? plan.instrument_type === 'single'
            : plan.instrument_type === 'all'
    ) : [];

    const handleOptionChange = () => {
        const newOption = selectedOption === "single" ? "all" : "single";
        setSelectedOption(newOption);
    };


    const cancelSubscription = () => {
        dispatch(membership.cancelSubscription('', { Token: token }))
    }

    function getInstrumentLabels(data, instrumentdetails) {
        let labelsSet = new Set();

        data?.forEach(plan => {
            plan.instruments.forEach(instrumentId => {
                let instrument = instrumentdetails?.find(item => item.id === parseInt(instrumentId));
                if (instrument) {
                    labelsSet.add(instrument.label);
                }
            });
        });

        return Array.from(labelsSet).join(', ');
    }
 
    


    let instrumentLabels = getInstrumentLabels(memberships?.userMembership, instruments?.instrument_details);


    const getSongsData = async (currentSongId) => {
        try {
            const data = await authService.getData(`${process.env.REACT_APP_API_URL}/api/songs?filter[path]=${currentSongId}&free=1`);
            if (data?.status === 200) {
                setSongsData(data?.data?.data?.lessons)
            } else {
                setSongsData([])

            }
        } catch (err) {
            console.error('Error:', err);
            setSongsData([])

        } finally {
        }
    };

    const getCourseLessonsData = async (currentCourseId) => {
        try {
            const data = await authService.getData(`${process.env.REACT_APP_API_URL}/api/lessons?parent=${currentCourseId}`);
            if (data?.status === 200) {
                setCoursesData(data?.data?.data)
            } else {
                setCoursesData([])
            }
        } catch (err) {
            console.error('Error:', err);
            setCoursesData([])

        } finally {
        }
    };


    const getPremiumData = async () => {
        setIsLoading(true)
        try {
            const data = await authService.getData(`${process.env.REACT_APP_API_URL}/api/school-student-details`);
            if (data?.status === 200) {
                setPremiumData(data?.data)
                setIsLoading(false)

            } else {
                setPremiumData([])
            }
        } catch (err) {
            console.error('Error:', err);
            setPremiumData([])

        } finally {
            setIsLoading(false)

        }
    };


    if (isLoading) {
        return <div className='' style={{ paddingTop: "15%", paddingBottom: '15%', }}>
            <div
                className="loader spinner-border m-5 d-table m-auto"
                role="status"
            >
                <span className="visually-hidden"></span>
            </div>
            <span className=" m-5 d-table m-auto">Loading...</span>
        </div>
    }


    return (
        <div className='premium-page'>
            <div className='container'>
                <img loading="lazy"className='premium' src='/assets/img/premium.svg' alt='' />
                <p className='head'>Welcome to TORRINS PREMIUM </p>
                <h1>{premiumData?.main_title}</h1>

                <div className='video-container'>
                    <video id="video" preload="auto" src={premiumData?.video_url} type="video/mp4" className="video" poster={premiumData?.poster_url} playsInline ref={videoRef} onEnded={handleVideoEnd} >
                    </video>
                    {
                        playingVideo ? <div className='pay-btn' onClick={pauseVideo}>
                            <img loading="lazy"src='/assets/img/BattleBands/pause2.svg' />
                        </div> : <div className='pay-btn' onClick={handlePlayVideo}>
                            <img loading="lazy"src='/assets/img/BattleBands/play.svg' />

                        </div>
                    }
                </div>


                <Expectations data={premiumData?.body_pointers?.data} title={premiumData?.body_title} />
            </div>
            <PremiumContents setCurrentTab={setCurrentTab} currentTab={currentTab} songsData={songsData} coursesData={coursesData} courseHash={courseHash} songsHash={songsHash} />

            <div className='HeaderFooterHomepageDiv'>

                <InstructorsPremium instructors={instructorDetails} />

                <MembershipPremium
                    filteredMemberships={filteredMemberships}
                    handleOptionChange={handleOptionChange}
                    selectedOption={selectedOption}
                    formattedDate={formattedDate}
                    cancelSubscription={cancelSubscription}
                    instrumentLabels={instrumentLabels}
                    title={premiumData?.membership_title}
                />

                <div className='membership-details'>
                    <MembershipFaq premiumPage={true} faqs={premiumData?.faqs?.data} />
                </div>

            </div>

            <div className='couldnot-found'>
                <div className='head'>
                    Could find what you were looking for?
                    <p>Write to us we will get back to you</p>
                </div>

                <Link className='btn' to={'/support'}>Contact Us</Link>

            </div>


        </div>
    )
}

export default Premium