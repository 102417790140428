import React from "react";

function FormInput({ register = '', label, error, ...inputProps }) {


  return (
    <>
      <input
        className={`form-control`}
        {...register}
        {...inputProps}
      />


      {label && <label className="custom-text-floating fname" htmlFor="validationCustom01">{label}</label>}
      {error?.message && <p class="incorrect_msg"><img loading="lazy"src="assets/img/error.svg" alt="" />{error.message}</p>
      }
    </>


  );
}

export { FormInput }