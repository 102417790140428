import React, { useState } from 'react'
import useWindowDimensions from '../../Helpers/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { lessonSongs } from '../../Action/lessonSongs.actions';

const PremiumContents = ({ setCurrentTab, currentTab, songsData, coursesData, courseHash, songsHash }) => {
    
    const { windowWidth } = useWindowDimensions();
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);
    const token = authState?.userDetails?.hash;
    const SocialToken = authState?.userDetails?.token;


    const courses = useSelector((state) => state.lesson?.mainInstruments);
    const menus = courses?.courses;

    const getPath = (path) => path?.[path.length - 1]?.hash;


    const truncate = (content, count = 100) => {
        const truncatedContent =
            content &&
                content?.length > count
                ? `${content.slice(0, count)}...`
                : content;
        return truncatedContent
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleSlug = (course,) => {

        dispatch({ type: 'SET_INSTRUMENT_PAGE_CONTENT', payload: { 'course': course, 'sub_course': {} } })
        dispatch({ type: 'SET_INSTRUMENT_PAGE_CONTENT', payload: { 'course': course?.lessons[0], 'sub_course': {} } })
        dispatch({ type: "SET_MAIN_ACTIVE_MENU", payload: course?.lessons[0]?.id });
        dispatch({ type: "SET_ACTIVE_MENU", payload: { course: course, course_type: 'lessons' } });
        dispatch(lessonSongs.getMainCategoryData(course?.lessons[0]?.id, '', { Token: token || SocialToken }));


    };

    const handleLessons = (course) => {
        dispatch({ type: 'SET_INSTRUMENT_PAGE_CONTENT', payload: { 'course': course, 'sub-course': {} } })
        dispatch({ type: "SET_MAIN_ACTIVE_MENU", payload: course?.lessons[0]?.id });
        dispatch({ type: 'SET_SEO_VALUE', payload: { type: 'LESSON', id: course?.id } })
        dispatch({ type: "SET_ACTIVE_MENU", payload: { course: course, course_type: 'lessons' } });
        dispatch(lessonSongs.getMainCategoryData(course?.lessons[0]?.id, '', { Token: token || SocialToken }));

    }
    return (
        <div className='premium-contents'>

            <h3 className='head'>Explore our FREE premium content exclusive for TORRINS students</h3>
            <ul className='list-items'>
                {menus?.courses?.map((item, index) => (
                    <li key={index} className={index === currentTab && 'active'} onClick={() => { setCurrentTab(index) }}>
                        {item?.title?.split(' ')[0]}
                    </li>
                ))
                }
            </ul>

            <div className='latest-launches'>

                {
                    windowWidth > 991 ? <div className='contents col-lg-12'>
                        <div className='latest-song col-lg-5'>
                            <h3>Free Song Lessons</h3>
                            <div className='song-contents mt-4'>
                                {songsData && songsData?.slice(0, 4)?.map((item, index) => (
                                    <Link to={`${process.env.REACT_APP_URL}/${getPath(item?.path)}/${item && item?.hash}-${item?.id}`} key={index} className='d-flex gap-2 mb-3'>
                                        <img loading="lazy"src={item?.poster} alt='' />
                                        <div>
                                            <p>{truncate(item?.title)}</p>
                                            <span>{item?.artist}</span>
                                        </div>
                                    </Link>
                                ))}
                                <Link className='btn btn-course' onClick={() => { handleSlug(menus?.courses[currentTab]) }} to={songsHash}>Explore All FREE Songs</Link>
                            </div>
                        </div>
                        <hr />
                        <div className='latest-course col-lg-5'>
                            <h3>Latest Courses</h3>
                            <div className='song-contents mt-4'>
                                {coursesData && coursesData?.slice(0, 4)?.map((item, index) => (
                                    <Link to={`${process.env.REACT_APP_URL}/${getPath(item?.path)}/${item && item?.hash}-${item?.id}`} key={index} className='d-flex gap-2 mb-3'>
                                        <img loading="lazy"src={item?.poster} alt='' />
                                        <div>
                                            <p>{truncate(item?.title)}</p>
                                            <span>{item?.instructor?.name}</span>
                                        </div>
                                    </Link>
                                ))}
                                <Link className='btn btn-song' onClick={() => { handleLessons(menus?.courses[currentTab]) }} to={courseHash}>Explore All FREE Courses</Link>
                            </div>


                        </div>
                    </div>
                        :
                        <div className='latest-release'>
                            <Box>
                                <Tabs value={value} onChange={handleChange} className='tab-name'>
                                    <Tab label="Song Lessons" />
                                    <Tab label="Courses" />
                                </Tabs>
                                {value === 0 && (
                                    <Box>

                                        <div className='song-contents mt-4'>
                                            {songsData && songsData?.slice(0, 4)?.map((item, index) => (
                                                <Link to={`${process.env.REACT_APP_URL}/${getPath(item?.path)}/${item && item?.hash}-${item?.id}`} key={index} className='d-flex gap-2 mb-3'>
                                                    <img loading="lazy"src={item?.poster} alt='' />
                                                    <div>
                                                        <p>{truncate(item?.title, 20)}</p>
                                                        <span>{item?.artist}</span>
                                                    </div>
                                                </Link>
                                            ))}
                                            <Link className='btn btn-course' onClick={() => { handleSlug(menus?.courses[currentTab]) }} to={songsHash}>Explore All FREE Songs</Link>
                                        </div>
                                    </Box>
                                )}
                                {value === 1 && (
                                    <Box>
                                        <div className='song-contents mt-4'>
                                            {coursesData && coursesData?.slice(0, 4)?.map((item, index) => (
                                                <Link to={`${process.env.REACT_APP_URL}/${getPath(item && item?.path)}/${item?.hash}-${item?.id}`} key={index} className='d-flex gap-2 mb-3'>
                                                    <img loading="lazy"src={item?.poster} alt='' />
                                                    <div>
                                                        <p>{truncate(item?.title, 20)}</p>
                                                        <span>{item?.instructor?.name}</span>
                                                    </div>
                                                </Link>
                                            ))}
                                            <Link className='btn btn-song' onClick={() => { handleLessons(menus?.courses[currentTab]) }} to={courseHash}>Explore All FREE Courses</Link>
                                        </div>
                                    </Box>
                                )}
                            </Box>

                        </div>

                }





            </div>

        </div>
    )
}

export default PremiumContents