export const dashboardConstant = {    
    GET_RECOMMENDED_SONGS_PENDING: "GET_RECOMMENDED_SONGS_PENDING",
    GET_RECOMMENDED_SONGS_SUCCESS: "GET_RECOMMENDED_SONGS_SUCCESS",
    GET_RECOMMENDED_SONGS_FAILURE: "GET_RECOMMENDED_SONGS_FAILURE",
    
    GET_LESSON_HISTORY_PENDING: "GET_LESSON_HISTORY_PENDING",
    GET_LESSON_HISTORY_SUCCESS: "GET_LESSON_HISTORY_SUCCESS",
    GET_LESSON_HISTORY_FAILURE: "GET_LESSON_HISTORY_FAILURE",
    
    GET_USER_ACTIVITY_PENDING: "GET_USER_ACTIVITY_PENDING",
    GET_USER_ACTIVITY_SUCCESS: "GET_USER_ACTIVITY_SUCCESS",
    GET_USER_ACTIVITY_FAILURE: "GET_USER_ACTIVITY_FAILURE",
    
    GET_LEARNING_PATH_PENDING: "GET_LEARNING_PATH_PENDING",
    GET_LEARNING_PATH_SUCCESS: "GET_LEARNING_PATH_SUCCESS",
    GET_LEARNING_PATH_FAILURE: "GET_LEARNING_PATH_FAILURE",
    
    GET_USER_ACHIEVEMENTS_PENDING:"GET_USER_ACHIEVEMENTS_PENDING",
    GET_USER_ACHIEVEMENTS_SUCCESS:"GET_USER_ACHIEVEMENTS_SUCCESS",
    GET_USER_ACHIEVEMENTS_FAILURE:"GET_USER_ACHIEVEMENTS_FAILURE",

    GET_DELETE_PERSONALISED_PENDING:"GET_DELETE_PERSONALISED_PENDING",
    GET_DELETE_PERSONALISED_SUCCESS:"GET_DELETE_PERSONALISED_SUCCESS",
    GET_DELETE_PERSONALISED_FAILURE:"GET_DELETE_PERSONALISED_FAILURE",

    GET_RECENT_COURSE_PENDING: "GET_RECENT_COURSE_PENDING",
    GET_RECENT_COURSE_SUCCESS: "GET_RECENT_COURSE_SUCCESS",
    GET_RECENT_COURSE_FAILURE: "GET_RECENT_COURSE_FAILURE",

}