import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLoginButton } from "react-social-login-buttons";
import { Link } from "react-router-dom";

import { FormInput } from "../../../../Utility/FormInput";
import { loginSchema } from "../../../../ValidationSchema/LoginSchema";
import { auth } from "../../../../Action/auth.actions";
import { authConstants } from "../../../../Constants/Auth";
import Button from "../../../../Utility/Button";
import '../../../../components/Common/CSS/common.css'
import "../../Courses.css";
import '../../../Authentication/Login/Login.scss'

import { authService } from "../../../../Adapters/auth.services";
import useWindowDimensions from "../../../../Helpers/useWindowDimensions";
import { Modal } from "react-bootstrap";

const schema = loginSchema;
const CourseSignIn = (props) => {
    const pathname = useLocation()
    const { windowWidth } = useWindowDimensions();

    const navigate = useNavigate()
    const authState = useSelector((state) => state.auth);
    const { socialToken, token } = authState?.userDetails;
    //useStates
    const [passwordLength, setPasswordLength] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [showVerification, setShowVerification] = useState(false);
    const dispatch = useDispatch();
    const emailSubmitted = useSelector((state) => state.auth.emailSubmitted)

    // Country Based Signup
    const { countryCurrency } = useSelector((state) => state.member);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
    });





    const handleCloseModal = () => {
        dispatch({ type: 'EMPTY_AUTH_STATE' })
        dispatch({ type: 'SET_AUTH_VALUE', payload: '' })
        if (props?.component === 'personalisation') {
            props?.setErrorLoginMsg(true)
        }

        props?.setShowSignIn(false);
        props?.setShowSignUp(false);
        reset()

        // removeBackdrop();
        props?.onHide();
    }

    const onSubmit = (data) => {
        const requestData = {
            ...data,
            login: data.login.trim(),
        };
        dispatch({ type: authConstants.SIGNIN_REQUEST });
        dispatch(auth.signIn(requestData)).then((response) => {
            if (props?.handleCloseSignIn && response.type === 'SIGNIN_SUCCESS') {
                if (props?.handleCloseSignIn) {
                    props?.handleCloseSignIn()

                }
            }


            if (props?.component === 'personalisation' && response?.type === "SIGNIN_FAILURE") {
                props?.setErrorLoginMsg(false)
            }
        })

        if (!props?.handleSuccess) {
            // removeBackdrop();
        }

    }

    //social login work here
    const handleSocialButton = async (data) => {
        handleCloseModal()
        dispatch({ type: authConstants.SOCIAL_LOGIN_REQUEST });
        await authService
            .sendRequest('/api/social-login', data)
            .then(response => {
                dispatch({ type: authConstants.SOCIAL_LOGIN_SUCCESS, payload: response.data });
                if (response?.data?.new_user) {
                    navigate(`${pathname?.pathname}?new_conversion`);
                } else if (response?.data?.new_user === false) {
                    navigate(`${pathname?.pathname}`)
                }
                dispatch(auth.getEditProfile({ token: token || socialToken || response?.data?.token }));
                dispatch({ type: "START_COURSE_INIT_STATE" });


            })
            .catch((error) => {
                return dispatch({ type: authConstants.SOCIAL_LOGIN_FAILURE, error: error });
            });

        // removeBackdrop();

    };

    useEffect(() => {
        setShowVerification(false)
        // if (props?.authView === 'signin') {
        //    setShow
        // }
    }, [props?.authView]);

    useEffect(() => {
        dispatch({ type: 'EMPTY_AUTH_STATE' })
        // var modal = document.getElementById('signModal');
        // modal.addEventListener('hide.bs.modal', function () {
        //     handleCloseModal();

        // });
        // document.getElementById('signin-action').click();
    }, []);

    useEffect(() => {
        setMessage(authState?.userDetails?.message)
    }, [authState])


    const handleWhatsAppLogin = () => {
        handleCloseModal()
        props?.setShowWhatsAppModal(true)
    }

    useEffect(() => {
        if (authState?.userDetails?.message === 'Please verify your email.') {
            setShowVerification(true)
        } else {
            setShowVerification('')
        }
    }, [authState?.userDetails])

    const handleVerfiy = () => {
        const requestData = {
            email: localStorage.getItem('login')
        }
        dispatch(auth.verifyEmail(requestData))
    }

    const handleKeyUp = (e) => {
        const inputValue = e.target.value;
        localStorage.setItem('login', inputValue);
    };

    const modalCLose = () => {
        props?.handleCloseSignIn()
        props?.onHide()
    }

    return (
        <>
            <Modal
                show={props?.show}
                onHide={modalCLose}
                centered
                size="md"
                animation={false}
                className="signup-modal signInModalcourse whatsapp-login-page modal-whatsapp"
            >
                <Modal.Body>
                    <button
                        type="button"
                        className="close"
                        onClick={modalCLose}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M20 20L4 4M20 4L4 20"
                                stroke="black"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                    <div className="signup-modal-area">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 p-0">
                                <div className="form-area">
                                    <div className="title">
                                        <h4>Welcome back!</h4>
                                    </div>
                                    <form
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="form-group">
                                            <FormInput
                                                id="floatingInputValue"
                                                type="text"
                                                identity="login"
                                                name="login"
                                                autoComplete="off"
                                                placeholder="Enter username or email address"
                                                register={register("login")}
                                                className="form-control"
                                                onKeyUp={(e) => handleKeyUp(e)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <FormInput
                                                id="floatingInputValue"
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                identity="password"
                                                name="password"
                                                autoComplete="off"
                                                placeholder="Enter Password"
                                                register={register("password")}
                                                error={
                                                    passwordLength >= 8
                                                        ? ""
                                                        : errors?.password
                                                }
                                            />
                                            <div class="input-group-addon">
                                                <Link to='#' id="showhide" onClick={() => setShowPassword(!showPassword)}><i className={`bi ${showPassword ? 'bi-eye' : 'bi-eye-slash'}`} aria-hidden="true"></i></Link>
                                            </div>
                                        </div>

                                        {authState?.userDetails?.code == 'ERROR' && props?.component == 'personalisation' && !props?.errorLoginMsg &&
                                            <div class="verify-email">
                                                <div class="d-flex gap-2 align-items-center">
                                                    <img loading="lazy" src="/assets/img/error-red.svg" alt="" />
                                                    <p class="red-error" style={{ color: 'crimson', fontSize: '13px' }}>{message}</p>
                                                </div>
                                            </div>
                                        }
                                        {showVerification &&
                                            <div class="verify-email">
                                                <div class="d-flex gap-2 align-items-center">
                                                    <img loading="lazy" src="/assets/img/error-red.svg" alt="" />
                                                    <p class="red-error" style={{ color: 'red', margin: '0px' }}>Please verify your email to Login</p>
                                                </div>
                                                {emailSubmitted ? <a href="javascript:void(0)" class="resend"> Resend Verification Link</a> : <a href="javascript:void(0)" class="resend" onClick={() => handleVerfiy()}> Resend Verification Link</a>}
                                            </div>
                                        }
                                        <div className="submit-btn">
                                            {authState?.submitted ? (
                                                <Button
                                                    label="Loading..."
                                                    disabled
                                                />
                                            ) : (
                                                <Button label="Sign In" />
                                            )}
                                            {/* <button>Log In</button> */}
                                        </div>

                                        <div className='login-contents mb-2'>
                                            <div className="register-with-google" style={{ textAlign: 'center' }}>
                                                {/* {props?.authView === 'signin' && */}
                                                    <LoginSocialGoogle
                                                        client_id="595434321762-t901vug4d7dq10a1ts2m20pv9p0r9rip.apps.googleusercontent.com"
                                                        access_type="online"
                                                        scope="openid profile email"
                                                        className='google-reg'
                                                        discoveryDocs="claims supported"
                                                        onLoginStart={() => handleCloseModal()}
                                                        onResolve={({ data }) => {
                                                            handleSocialButton(
                                                                data
                                                            );
                                                        }}
                                                        onReject={(err) => {
                                                            console.log(err);
                                                        }}
                                                    >
                                                        {authState.socialSubmit ? <GoogleLoginButton className='google' text="Loading..." /> : <GoogleLoginButton className='google' text={windowWidth > 761 ? '' : 'Login with Google'} />}

                                                    </LoginSocialGoogle>
                                                {/* } */}
                                            </div>
                                            <div class="register-with-email" >
                                                <a className="" href="javascript:void(0);" onClick={handleWhatsAppLogin} >
                                                    <img loading="lazy" src='/assets/img/Login/what.svg' alt='' />
                                                    {windowWidth > 761 ? '' : 'Login with WhatsApp'}

                                                </a>
                                            </div>
                                        </div>

                                        <div className="have-login-link">
                                            {
                                                countryCurrency === "INR" ? (
                                                    <p>
                                                        Don't have an account?{" "}
                                                        <a
                                                            href="javascript:void(0);"
                                                            onClick={() => { props?.handleCloseSignIn(); props?.setShowWhatsAppRegisterModal(true); reset() }}
                                                        >
                                                            Sign Up
                                                        </a>    
                                                    </p>
                                                ) : (
                                                    <p>
                                                        Don't have an account?{" "}
                                                        <a
                                                            href="javascript:void(0);"
                                                            onClick={() => { props?.handleCloseSignIn(); props?.handleSwitchSignIn(); reset() }}
                                                        >
                                                            Sign Up
                                                        </a>
                                                    </p>
                                                )
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-6 p-0">
                                    <div className="img-area">
                                        <img loading="lazy"src="" alt="" />
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* <a href="javascript:void(0);" id="signin-action" data-bs-toggle="modal" data-bs-target="#signModal" style={{ display: 'none' }}>Signin</a> */}
        </>
    );
};

export default CourseSignIn;
