import React from 'react'
import Select from 'react-select'

 
export const CountrySelect = ({value , onChange , options , disabled}) => {
    return (
      <>
   <Select
    className="custom-selects country"
    value={value}
    onChange={onChange}
    options={options}
    isSearchable={true}
    isDisabled={disabled}
    styles={{
        control: (base, state) => ({
            ...base,
            marginLeft: '0px',
            border: '1px solid rgb(214, 214, 214)',
            borderColor: state.isFocused ? '#86b7fe' : 'rgb(214, 214, 214)',
            boxShadow: state.isFocused
                ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                : 'none',
            background: 'none',
            height: '40px',
            minHeight: '40px',
        }),
        container: (provided) => ({
            ...provided,
            width: '90px',
            height: '40px',
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '0px 8px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent:'center',
            width:'100%',
            height:'100%'
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#ffc444' : base.backgroundColor,
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        singleValue: (base) => ({
            ...base,
            lineHeight: 'normal',
            padding:'0px' ,
            
        }),
        indicatorsContainer: (base) => ({
            ...base,
            padding: '0px',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: '0px 8px 0px 0px',
            margin: '0px',
        }),
    }}
/>

      </>
    )


}

