import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../../../../Action/auth.actions'
import { Link } from 'react-router-dom'

const VerifyEmailModal = (props) => {
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth)
    const emailSubmitted = useSelector((state) => state.auth.emailSubmitted)



    return (
  

        <div class="form-area verify-email">
            <img loading="lazy"src="/assets/img/verify.svg" alt="" class="icon" />
            <h2>Verify your email address</h2>
            <p class="verify-instruction-msg">We have sent a verification link to your 
                email address <br />
            <b>{localStorage.getItem('email') || props?.email}</b>  
            </p>
            <div class="verify-by">
                <p>To open your email click on your email provider</p>
                <div>
                    <a href={process.env.REACT_APP_EMAIL_URL} class="google-reg" target='_blank'><img loading="lazy"src="/assets/img/google 1.svg" alt="" /></a>
                    <a href={process.env.REACT_APP_OUTLOOK_URL} class="google-reg" target='_blank'><img loading="lazy"src="/assets/img/outlook.svg" alt="" /></a>
                    <a href={process.env.REACT_APP_YAHOO_URL} class="google-reg" target='_blank'><img loading="lazy"src="/assets/img/yahoo.svg" alt="" /></a>
                </div>
            </div>
            <div class="login-if-account">
                Did not receive verification link?{emailSubmitted ?<Link to="#" >Sending...</Link> : <Link href="#" onClick={(e) => props?.handleResendEmail(e)}>Resend Link</Link>}
            </div>
            
        </div>
        
    )
}

export default VerifyEmailModal
