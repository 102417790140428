import React from 'react'
import { Link } from 'react-router-dom'

const NarayanaQuery = ({ bobData }) => {
  return (
    <div className='narayana-query'>

      <div className='contact'>
        <div className='first'>
          <h4>What are you waiting for?</h4>
          <p>Registration ends on <br /> <span>{bobData?.event_date}</span></p>


          <div className='d-flex align-start justify-content-start gap-4 mt-2'>
            <Link className='register'   target='_blank'  to={bobData?.registration_link}>Register Now</Link>
            <Link className='rules-btn'   target='_blank'  to={bobData?.rules_and_regulations_file_url}>View Competition Rules</Link>
          </div>

        </div>

        <img loading="lazy"src='/assets/img/BobNarayana/children.svg' />

      </div>
      <div className='query'>
        <h4>For any queries contact</h4>

        <div className='d-flex '>
          <p>
            <img loading="lazy"src='/assets/img/BobNarayana/mail.svg' />
            <a href={`mailto:${bobData?.contact_email}`}>{bobData?.contact_email}</a>
          </p>
          <p>
            <img loading="lazy"src='/assets/img/BobNarayana/phone.svg' />
            <a href={`tel:${bobData?.contact_number}`}>{bobData?.contact_number}</a>
          </p>

        </div>

      </div>
    </div>
  )
}

export default NarayanaQuery