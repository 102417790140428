import React, { useEffect, useState } from 'react';
import './BattleBandsNaryana.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bandsAction } from '../../Action/bands.action';
import CountdownTimerNarayana from './NaraynaTimer';
import AboutNarayana from './AboutNarayana';
import HowToRegister from './HowToRegister';
import TopPerformer from './TopPerformer';
import NarayanaFaq from './NarayanaFaq';
import NarayanaQuery from './NarayanaQuery';
import { authService } from '../../Adapters/auth.services';
import { Helmet } from 'react-helmet';

const BattleBandsNaryana = () => {

    const dispatch = useDispatch();
    const [bobData, setBobData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getBobData()

    }, [])

    const getBobData = async () => {
        setIsLoading(true)
        try {
            const data = await authService.getData(`${process.env.REACT_APP_API_URL}/api/battle_of_band_details/narayana`);
            if (data?.status === 200) {
                setBobData(data?.data)
            } else {
                setBobData([])
            }
        } catch (err) {
            console.error('Error:', err);
        } finally {
            setIsLoading(false)
        }
    };

    if (isLoading) {
        return <div className=' narayana-loader' style={{ paddingTop: "15%", paddingBottom: '15%', }}>
            <div
                className="loader spinner-border m-5 d-table m-auto"
                role="status"
            >
                <span className="visually-hidden"></span>
            </div>
            <span className=" m-5 d-table m-auto">Loading...</span>
        </div>
    }

    let homepageSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Torrins",
        "url": "https://www.torrins.com/",
        "logo": "https://www.torrins.com/assets/homepage/logo.png",
        "sameAs": [
            "https://twitter.com/Torrinsonline",
            "https://www.instagram.com/torrinsindia",
            "https://www.youtube.com/user/torrinsonline",
            "https://www.linkedin.com/company/torrinsindia/mycompany"
        ]
    }

    return (
        <>
            <Helmet>
                <title>West Bengal’s Largest School Band Competition | Torrins x Narayana Group</title>
                {process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                    <meta name="robots" content="noindex, nofollow" />
                ) : (
                    ""
                )}
                <meta name="description" content="Join the excitement at West Bengal’s largest school band competition, co-powered by Torrins and Narayana Group of Schools! Over 300 schools compete to showcase their musical talents and perform on the biggest stage. Discover more about the event and register today!" />
                <link
                    rel="canonical"
                    href='/battle-of-bands/narayana'
                />
                <script type="application/ld+json">
                    {JSON.stringify(homepageSchema)}
                </script>
            </Helmet>
            <div className='bob-naryana'>
                {bobData && bobData?.event_background_image && <div className="containers-band">
                    <div className="top-section" style={{ backgroundImage: `url(${bobData && bobData?.event_background_image})` }}>
                        <div className='d-flex presents align-items-center justify-content-center'>
                            <img loading="lazy"src='/assets/img/HomeNew/logo.svg' alt='' />
                            PRESENTS
                        </div>
                        <h2>{bobData?.event_name}</h2>
                        <h3>{bobData?.event_title1}</h3>
                        <p>{bobData?.event_title2}</p>
                        <div className='btn-container'>
                            <Link to={bobData?.registration_link} target='_blank'>Register Now</Link>
                            <p className='copowerd'>Co-Powered by <img loading="lazy"src='/assets/img/BobNarayana/logo.svg' /></p>
                        </div>
                    </div>
                    <div className="timer narayana-timer">
                        <CountdownTimerNarayana aboutBands={bobData} />
                    </div>
                </div>}

                {bobData && <AboutNarayana bobData={bobData} />}

                {bobData && <HowToRegister bobData={bobData} />}

                {bobData?.top_performers?.data && <TopPerformer bobData={bobData} />}

                {bobData?.faq?.data && <NarayanaFaq bobData={bobData} />}

                {bobData && <NarayanaQuery bobData={bobData} />}

            </div>
        </>
    )
}

export default BattleBandsNaryana