import React, { useEffect, useRef, useState } from 'react'
import SideBar from '../../../components/Common/SideBar'
import Select from 'react-select';
import './/MembershipPlan.scss';
import { components } from 'react-select';
import BreadCrumbs from '../../../components/Common/BreadCrumbs';
import breadcrumbs from '../../../components/BreadCrumbs/breadcrumbsObj';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../../../Adapters/auth.services';
import { useSelector } from 'react-redux';
import CustomSuccessToast from '../../../components/CustomToaster/CustomSuccessToaster';
import { toast } from 'react-toastify';
import CustomErrorToast from '../../../components/CustomToaster/CustomErrorToast';

const MembershipCancelReason = () => {
    const currentUrl = useLocation();
    const textareaRef = useRef(null);
    const authState = useSelector((state) => state.auth);
    const socialToken = authState?.userDetails?.token;
    const token = authState.userDetails.hash;
    const navigate =useNavigate();

    const cancelSubscriptionData = useSelector((state) => state.member.cancelSubscriptionData);


    const [isLoading, setIsLoading] = useState(false)
    const [selected, setSelected] = useState('')
    const [options, setOptions] = useState('')
    const [text, setText] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        getCancelReasons()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const getCancelReasons = async () => {
        try {
            const data = await authService.getData(`${process.env.REACT_APP_API_URL}/api/cancellation-reasons`);
            if (data?.status === 200) {
                const mappedData = data?.data?.data?.map((option, index) => ({
                    label: option.reason,
                    value: option.reason,
                    id: option?.id
                }));

                setOptions(mappedData)
            } else {
            }
        } catch (err) {
            console.error('Error:', err);
        } finally {
        }
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img loading="lazy"src='/assets/img/arrow-black.svg' alt="dropdown icon" style={{ width: '12px', height: '12px' }} />
            </components.DropdownIndicator>
        );
    };


    const handleChange = (e) => {
        setSelected(e)
    }


    const handleTextChange = (e) => {
        setText(e.target.value)
        const textarea = textareaRef?.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }


    useEffect(() => {
        if (
            (selected?.value?.length > 0) &&
            cancelSubscriptionData?.key?.trim()?.length > 0
        ) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [selected, text, cancelSubscriptionData]);
    



    const postCancelReasons = async () => {

        setIsLoading(true)
        try {
            const payload = {
                cancellation_reason_id: selected?.id,
                cancellation_reason_text: text
            }
            const data = await authService.sendTokenInHeader(`/api/subscription/save-cancel-reason/${cancelSubscriptionData?.key}`, payload, { Token: token || socialToken },);
            if (data?.status === 200) {
                toast(<CustomSuccessToast message={data?.data?.message} ></CustomSuccessToast>)
                window.location.href = cancelSubscriptionData?.url

            } else {
                toast(<CustomErrorToast message={data?.data?.message} ></CustomErrorToast>)
            }
        } catch (err) {
            console.error('Error:', err);
        } finally {
        setIsLoading(false)

        }
    };

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const sidebarClass = isHovered ? "col-md-3" : "col-md-1";
    const sidebarViewClass = isHovered ? "9" : "11";

    return (
        <div className='authenticate cancel-membership-page'>

            <BreadCrumbs data={breadcrumbs.membershipPlansCancelReasons} currentPage={currentUrl.pathname} />

            <div className="row  px-0">
                <SideBar 
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    sidebarClass={sidebarClass}
                    isHovered={isHovered}
                />

                <div className={`right-section col-xxl-${sidebarViewClass} col-xl-${sidebarViewClass} col-lg-${sidebarViewClass} col-lg-${sidebarViewClass} col-md-${sidebarViewClass} col-sm-12 membership-setting membership-settings-section`}>
                    <div className="p-3 membership-cancel-subscription confirmation-cancel-subscription h-100  ">
                        <div className="  bg-white  gap-2 mt-2  membership-cancel-subscription-container">

                            <div className="d-flex align-items-center justify-content-start gap-2">
                                <Link to={'/membership/cancel'}>
                                    <img loading="lazy"src="/assets/img/back-arrow.png" style={{ cursor: 'pointer' }} />
                                </Link>
                                <h4 className="p-0 m-0">Cancel my membership</h4>
                            </div>

                            <div className="confirmation-cancel mt-3 reasons">
                                <h3>Hey {authState?.userProfile?.user_data?.name || authState?.userDetails?.name}, Sorry to see you go</h3>
                                <div className="mt-3">
                                    <label>Select your reason for cancelation</label>

                                    <Select
                                        className="custom-selects"
                                        value={selected}
                                        onChange={handleChange}
                                        options={options}
                                        components={{ DropdownIndicator }}
                                        isSearchable={true}
                                        menuPlacement="auto"
                                        menuPortalTarget={document.body}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                marginLeft: '0px',
                                                boxShadow: '1px solid #000',
                                                background: 'none',
                                                borderColor: state.isFocused ? '#000' : '#ccc',
                                                '&:hover': {
                                                    borderColor: '#000',
                                                },
                                            }),
                                            container: (provided) => ({
                                                ...provided,
                                                width: '160px',
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isSelected ? '#ffc444' : base.backgroundColor,
                                            }),
                                            indicatorSeparator: (base) => ({
                                                display: 'none',
                                                padding: '0px'
                                            }),
                                            valueContainer: (base) => ({
                                                ...base,
                                                padding: ' 3px 0px 3px 8px',
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                            }),
                                            indicatorsContainer: (base) => ({
                                                ...base,
                                                padding: '0px',
                                            }),
                                            dropdownIndicator: (base) => ({
                                                ...base,
                                                padding: '0px 8px 0px 0px',
                                                margin: '0px',
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                                maxHeight: '300px',
                                                overflowY: 'auto',
                                            }),
                                        }}
                                    />
                                </div>
                                <div className="mt-3">
                                    <label>Please share your experience with us</label>
                                    <textarea ref={textareaRef} style={{ overflow: 'hidden', resize: 'none', maxHeight: '400px' }} onChange={(e) => { handleTextChange(e) }} value={text} placeholder="Type here"></textarea>
                                </div>
                            </div>
                            <div className="d-flex text-start align-items-center btn-confimation ">
                                <button className="btn btn-subscribe me-2" disabled={isDisabled || isLoading} onClick={postCancelReasons} >{isLoading ? 'Submitting':'Cancel My Membership'}</button>
                                <Link className="btn btn-cancel justify-content-start" to={'/dashboard'}> Don't cancel continue learning</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MembershipCancelReason


