import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

const AboutNarayana = ({ bobData }) => {

    const videoRef = useRef(null);
    const [playingVideo, setPlayingVideo] = useState(false);

    const handlePlayVideo = () => {
        if (videoRef?.current) {
            videoRef?.current?.play();
            setPlayingVideo(true)
        }
    };
    const pauseVideo = () => {
        if (videoRef?.current) {
            videoRef.current.pause();
            setPlayingVideo(false);
        }
    };

    const handleVideoEnd = () => {
        setPlayingVideo(false);
    };
    return (
        <div className='about-narayana'>
            <h3>About the competition</h3>
            <p>{bobData?.event_description}</p>
            <Link className='register-now' to={bobData?.registration_link} target='_blank' >Register Now</Link>

            <div className='video-container'>
                <video id="video" preload="auto" src={bobData?.video_link} type="video/mp4" className="video" poster={bobData?.video_poster} playsInline ref={videoRef} onEnded={handleVideoEnd} >
                </video>

                {bobData &&
                    playingVideo ? <div className='pay-btn' onClick={pauseVideo}>
                    <img loading="lazy"src='/assets/img/BobNarayana/pause.svg' />
                </div> : <div className='pay-btn' onClick={handlePlayVideo}>
                    <img loading="lazy"src='/assets/img/BobNarayana/play.svg' />

                </div>
                }
            </div>
        </div>

    )
}

export default AboutNarayana