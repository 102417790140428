import { chordsConstants } from "../Constants/chordsConstants"

const initialState = {
    chordsData: [],
    loading: false,
    chordTabs: [],
    instruments: [],
    instructors: [],
    totalSong: 0,
    genres: [],
    activeCategory: {},
    playlist: [],
    chordsListingPagination: {},
    loadMoreData: [],
    activeSearchValue: '',
    chordsDetailsData: {},
    similarChords: {},
    similarChordsLoading: false,
    LikeDislikedData: [],
    loadingMore: false,

}
let index, chordTabs = [];
export const chordsReducer = (state = initialState, action) => {
    switch (action.type) {
        case chordsConstants.GET_CHORDS_PENDING:
            return {
                ...state,
                chordsData: [],
                // loadMoreData:[],
                loading: true,
            };
            break;

        case chordsConstants.GET_CHORDS_SUCCESS:
            let updatedChordsTabs = [...state.chordTabs];

            const activeMenuIndex = state.chordTabs.findIndex(tab => tab.id == state.activeMenu) || 0;

            if (activeMenuIndex !== -1) {
                updatedChordsTabs[activeMenuIndex].chords = action.payload.total;
            }

            let updatedLoadMoreData = [...state.loadMoreData];

            let newChords = [];

            if (action.payload?.isSearch || action.payload.activeSearch === state.activeSearchValue) {
                newChords = action.payload.chords.filter(song => {
                    return !updatedLoadMoreData.some(existingSong => existingSong.id === song.id);
                });
            } else {
                newChords = action.payload.chords.filter(song => {
                    return !updatedLoadMoreData.some(existingSong => existingSong.id === song.id);
                });
            }

            updatedLoadMoreData = updatedLoadMoreData.concat(newChords);

            // Ensure no duplicates in the final array
            updatedLoadMoreData = updatedLoadMoreData.filter((song, index, self) =>
                index === self.findIndex((t) => t.id === song.id)
            );

            return {
                ...state,
                chordsData: action.payload.chords ?? [],
                chordsListingPagination: action.payload.pagination,
                loadMoreData: updatedLoadMoreData,
                chordTabs: updatedChordsTabs,
                loading: false
            };

            break;


        case chordsConstants.GET_CHORDS_FAILURE:
            if (state.chordTabs.length > 0) {

                index = state.chordTabs.findIndex(tab => tab.id == state.activeMenu);
                if (index !== -1) {
                    chordTabs = state.chordTabs;
                    chordTabs[index].songs = 0;
                } else {
                    chordTabs = state.chordTabs
                }
            }

            return {
                ...state,
                chordsData: action.payload,
                loading: false,
                chordsData: {},
                loadMoreData: []

            };
            break;
        case chordsConstants.GET_TABS_CHORDS_PENDING:
            return {
                ...state,
                loading: true,
                chordsData: [],
                loadMoreData: [],
                free_count: 0

            };
            break;

        case chordsConstants.GET_TABS_CHORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                free_count: action.payload,
                chordTabs: action.payload.lessons,
                genres: action.payload?.genre,
                instruments: action.payload?.instrument,
                instructors: action.payload?.instructors,
                totalSong: action.payload?.chords,
                activeCategory: action.payload?.length > 0 ? action.payload[0] : {},
            };

            break;

        case chordsConstants.GET_TABS_CHORDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                chordsData: [],
                chordTabs: [],
                free_count: 0,
                loadMoreData: []

            };
            break;


        case 'SET_CHORD_ACTIVE_MENU':
            return {
                ...state,
                activeMenu: action.payload,
                loadMoreData: [],
                error: { "type": "active", "message": action?.payload?.message }
            };
            break;

        case 'SET_CHORD_SEARCH_VALUE':
            return {
                ...state,
                activeSearchValue: action.payload,
            };
            break;



        case chordsConstants.GET_CHORDS_DETAILS_PENDING:
            return {
                ...state,
                chordsDetailsData: {}

            };
            break;

        case chordsConstants.GET_CHORDS_DETAILS_SUCCESS:
            return {
                ...state,
                chordsDetailsData: action.payload
            };

            break;

        case chordsConstants.GET_CHORDS_DETAILS_FAILURE:
            return {
                ...state,
                chordsDetailsData: {}

            };
            break;

        case chordsConstants.GET_SIMILAR_CHORDS_PENDING:
            return {
                ...state,
                similarChords: {},
                similarChordsLoading: true

            };
            break;

        case chordsConstants.GET_SIMILAR_CHORDS_SUCCESS:
            return {
                ...state,
                similarChords: action.payload,
                similarChordsLoading: false
            };

            break;

        case chordsConstants.GET_SIMILAR_CHORDS_FAILURE:
            return {
                ...state,
                similarChords: {},
                similarChordsLoading: false

            };
            break;

        case chordsConstants.GET_LIKEDISLIKE_PENDING:
            return {
                ...state,
                LikeDislikedData: []

            };
            break;

        case chordsConstants.GET_LIKEDISLIKE_SUCCESS:
            return {
                ...state,
                LikeDislikedData: action.payload
            };

            break;

        case chordsConstants.GET_LIKEDISLIKE_FAILURE:
            return {
                ...state,
                LikeDislikedData: []

            };
            break;

        case 'UPDATE_CHORDS_LOADMORE_DATA':
            return {
                ...state,
                loadMoreData: [],
                chordsData: [],
                chordsListingPagination:{}
                

            };
            break;


        default:
            return state;


    }
}
