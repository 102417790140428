import { songsConstants } from "../Constants/SongsConstants";

const initialState = {
    loading: false,
    error: {},
    songsListing: {},
    songTabs: [],
    instruments: [],
    instructors: [],
    totalSong: 0,
    genres: [],
    activeCategory: {},
    songDetails: [],
    playlist: [],
    songsListingPagination: {},
    loadMoreData: [],
    activeSearchValue: '',
    playlistLoading: false,
    playlistLoadMoreData: [],
    totalPlaylist: 0,
    free_count: 0,
    clickSong: false,
    songTabsExtras: [],
    loadMoreDataExtras: [],
    loadingExtras: false

};

let index, songTabs = [], songTabsExtras = [];
export const songsReducer = (state = initialState, action) => {
    switch (action.type) {
        case songsConstants.GET_SONGS_PENDING:
            return {
                ...state,
                loading: true,
                songsListing: [],
            };
            break;

        case songsConstants.GET_SONGS_SUCCESS:
            let updatedSongTabs = [...state.songTabs];

            const activeMenuIndex = state.songTabs.findIndex(tab => tab.id == state.activeMenu);


            if (activeMenuIndex !== -1) {
                updatedSongTabs[activeMenuIndex].songs = action.payload.total;

            } else {

            }


            let updatedLoadMoreData = []
            let newSongs = []

            if (action.payload?.isSearch) {
                newSongs = action.payload.songs.filter(song => {
                    return !updatedLoadMoreData.some(existingSong => existingSong.id === song.id);
                });
                updatedLoadMoreData = [...state.loadMoreData];
                updatedLoadMoreData = updatedLoadMoreData.concat(newSongs);

            } else if (action.payload.activeSearch === state.activeSearchValue) {
                newSongs = action.payload.songs.filter(song => {
                    return !updatedLoadMoreData.some(existingSong => existingSong.id === song.id);
                });
                updatedLoadMoreData = [...state.loadMoreData];
                updatedLoadMoreData = updatedLoadMoreData.concat(newSongs);
            } else {
                newSongs = action.payload.songs.filter(song => {
                    return !updatedLoadMoreData.some(existingSong => existingSong.id === song.id);
                });
                updatedLoadMoreData = [...state.loadMoreData];
                updatedLoadMoreData = updatedLoadMoreData.concat(newSongs)
            }

            // Ensure no duplicates in the final array
            updatedLoadMoreData = updatedLoadMoreData.filter((song, index, self) =>
                index === self.findIndex((t) => t.id === song.id)
            );


            return {
                ...state,
                loading: false,
                songsListing: action.payload.songs ?? [],
                songsListingPagination: action.payload.pagination,
                loadMoreData: updatedLoadMoreData,
                songTabs: updatedSongTabs,
            };

            break;

        case songsConstants.GET_SONGS_FAILURE:
            if (state.songTabs.length > 0) {

                index = state.songTabs.findIndex(tab => tab.hash === state.activeMenu?.hash);
                if (index !== -1) {
                    songTabs = state.songTabs;
                    songTabs[index].songs = 0;
                } else {
                    songTabs = state.songTabs
                }
            }

            return {
                ...state,
                loading: false,
                error: action.error,
                songsListing: {},
                loadMoreData: [],
                loadMoreDataExtras: [],
                songTabs: songTabs

            };
            break;


        case songsConstants.GET_TABS_SONGS_PENDING:
            return {
                ...state,
                // loading: true,
                songsListing: {},
                loadMoreData: [],
                loadMoreDataExtras: [],
                free_count: 0

            };
            break;

        case songsConstants.GET_TABS_SONGS_SUCCESS:

            return {
                ...state,
                // loading: false,
                free_count: action.payload,
                songTabs: action.payload?.lessons,
                genres: action.payload?.genre,
                instruments: action.payload?.instrument,
                instructors: action.payload?.instructors,
                totalSong: action.payload?.songs,
                activeCategory: action.payload.length > 0 ? action.payload[0] : {},
            };

            break;

        case songsConstants.GET_TABS_SONGS_FAILURE:
            return {
                ...state,
                // loading: false,
                error: action.error,
                songsListing: {},
                songTabs: [],
                songTabsExtras: [],
                free_count: 0

            };
            break;

        case 'SET_SONG_ACTIVE_MENU':
            return {
                ...state,
                activeMenu: action.payload,
                loadMoreData: [],
                loadMoreDataExtras: [],
                playlistLoadMoreData: [],
                error: { "type": "active", "message": action?.payload?.message }
            };
            break;

        case 'SET_SEARCH_VALUE':
            return {
                ...state,
                activeSearchValue: action.payload,
            };
            break;


        case 'UPDATE_LOADMORE_DATA':
            return {
                ...state,
                loadMoreData: [],
                playlistLoadMoreData: [],
                loadMoreDataExtras: [],

            };
            break;

        case songsConstants.GET_SONGS_SLUG_PENDING:
            return {
                ...state,
                playlistLoading: true,
                songDetails: []
            };
            break;

        case songsConstants.GET_SONGS_SLUG_SUCCESS:
            let updatedLoadMorePlaylistData = []

            if (action.payload?.isSearch) {
                updatedLoadMorePlaylistData = [...state.playlistLoadMoreData];
                updatedLoadMorePlaylistData = updatedLoadMorePlaylistData.concat(action.payload.lessons);

            } else if (action.payload.activeSearch === state.activeSearchValue) {
                updatedLoadMorePlaylistData = [...state.playlistLoadMoreData];
                updatedLoadMorePlaylistData = updatedLoadMorePlaylistData.concat(action.payload.lessons);
            } else {
                updatedLoadMorePlaylistData = [...state.playlistLoadMoreData];
                updatedLoadMorePlaylistData = updatedLoadMorePlaylistData.concat(action.payload.lessons);
            }

            return {
                ...state,
                playlistLoading: false,
                songDetails: action.payload,
                genres: action.payload?.genre,
                instruments: action.payload?.instrument,
                instructors: action.payload?.instructors,
                totalSong: action.payload?.totalSongs,
                playlistLoadMoreData: updatedLoadMorePlaylistData,
                totalPlaylist: action.payload.totalPlaylist,
                activeCategory: action.payload.length > 0 ? action.payload[0] : [],
            };
            break;

        case songsConstants.GET_SONGS_SLUG_FAILURE:
            return {
                ...state,
                playlistLoading: false,
                error: action.error,
                songDetails: [],

            };
            break;

        case songsConstants.GET_PLAYLIST_PENDING:
            return {
                ...state,
                loading: true,
                playlist: []
            };
            break;

        case songsConstants.GET_PLAYLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                playlist: action.payload?.data,
            };

            break;

        case songsConstants.GET_PLAYLIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                playlist: []

            };
            break;

        case 'SET_CLICKED_STATE':
            return {
                ...state,
                clickSong: action.payload
            };
            break;

        case 'RESET_PAGINATION':
            return {
                ...state,
                songsListingPagination: { "next_page" : null}
            }
            break;
        case songsConstants.GET_SONGS_EXTRAS_PENDING:
            return {
                ...state,
                loadingExtras: true,
                songsListing: [],
            };
            break;

        case songsConstants.GET_SONGS_EXTRAS_SUCCESS:
            const updatedSongTabsExtras = [...state.songTabsExtras];
            const activeMenuIndexExtras = state.songTabsExtras.findIndex(tab => tab.id === state.activeMenu);

            if (activeMenuIndexExtras !== -1) {
                updatedSongTabsExtras[activeMenuIndexExtras].songs = action.payload.total;
            }

            let updatedLoadMoreDataExtras = [...state.loadMoreDataExtras];
            let newSongsExtras = action.payload.songsExtras.filter(song =>
                !updatedLoadMoreDataExtras.some(existingSong => existingSong.id === song.id)
            );

            updatedLoadMoreDataExtras = updatedLoadMoreDataExtras.concat(newSongsExtras);

            // Ensure no duplicates in the final array
            updatedLoadMoreDataExtras = updatedLoadMoreDataExtras.filter((song, index, self) =>
                index === self.findIndex(t => t.id === song.id)
            );

            return {
                ...state,
                loadingExtras: false,
                songsListing: action.payload.songsExtras || [],
                // songsListingPagination: action.payload.pagination,
                loadMoreDataExtras: updatedLoadMoreDataExtras,
                songTabsExtras: updatedSongTabsExtras,
            };
            break;

        case songsConstants.GET_SONGS_EXTRAS_FAILURE:
            if (state.songTabs.length > 0) {

                index = state.songTabs.findIndex(tab => tab.hash === state.activeMenu?.hash);
                if (index !== -1) {
                    songTabs = state.songTabs;
                    songTabs[index].songs = 0;
                } else {
                    songTabs = state.songTabs
                }
            }

            return {
                ...state,
                loadingExtras: false,
                error: action.error,
                songsListing: {},
                loadMoreDataExtras: [],
                songTabs: songTabs

            };
            break;
        default:
            return state;
    }
}