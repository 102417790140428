import { bandsConstants } from "../Constants/bandsConstant.js";
import { bandsService } from "../Adapters/bands.service.js";
import { post } from "../Adapters/xhr/index.jsx";
import CustomSuccessToast from "../components/CustomToaster/CustomSuccessToaster.js";
import { toast } from "react-toastify";

export const bandsAction = {
    getAboutBands,
    getEventsData,
    getZonesData,
    getBandsFaq,
    getYearsImages,
    getUrlsData,
    getHallOfFameData,
    postBobFolowers,
    getFollowersCount,
    // postHOFMessage

}


function getAboutBands() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/battle-of-bands-about`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getAboutBands(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_BANDS_INFO_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_BANDS_INFO_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_BANDS_INFO_SUCCESS, payload: data };
    }
}


function getEventsData() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/highlight-events`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getEventsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_EVENTS_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_EVENTS_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_EVENTS_SUCCESS, payload: data };
    }
}


function getZonesData() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/battle-of-band-zone`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getZonesData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_ZONES_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_ZONES_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_ZONES_SUCCESS, payload: data };
    }
}


function getBandsFaq() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/battle-of-band-faq`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getBandsFaq(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_BANDS_FAQ_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_BANDS_FAQ_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_BANDS_FAQ_SUCCESS, payload: data };
    }
}


function getYearsImages() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/battle-of-band-through-the-year`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getYearsImageData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_YEARSIMAGES_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_YEARSIMAGES_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_YEARSIMAGES_SUCCESS, payload: data };

    }
}



function getUrlsData() {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/battle-of-band-video`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getUrlsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_VIDEOURL_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_VIDEOURL_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_VIDEOURL_SUCCESS, payload: data };
    }
}





function getHallOfFameData(id , params) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/HOFGetMessages?band_id=${id}`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getZonesData(url.href , params)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_HALL_OF_FAMEDATA_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_HALL_OF_FAMEDATA_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_HALL_OF_FAMEDATA_SUCCESS, payload: data };
    }

}


function postBobFolowers(id, band_id, headers, params ) {

    let url = `/api/HOFUserFollowup?user_id=${id}&band_id=${band_id}`;
    return (dispatch) => {
        dispatch(request());
        post(url, params, headers)
            .then((response) => {
                if (response.status == 200) {
                    toast(<CustomSuccessToast message={'Started Following.'} ></CustomSuccessToast>)
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.FOLLOW_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.FOLLOW_REQUEST };
    }

    function success(data) {
        return { type: bandsConstants.FOLLOW_SUCESS, payload: data };
    }

}



function getFollowersCount(id , params) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/HOFUserFollowupCount?band_id=${id}`);
    return (dispatch) => {
        dispatch(request());
        bandsService.getfollowers(url.href , params)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                    dispatch(errors(response));
                }
            })
            .catch((error) => {
                dispatch(errors(error));
            });
    };

    function errors(error) {
        return { type: bandsConstants.GET_FOLLOWER_FAILURE, error: error };
    }

    function request() {
        return { type: bandsConstants.GET_FOLLOWER_PENDING };
    }

    function success(data) {
        return { type: bandsConstants.GET_FOLLOWER_SUCESS, payload: data };
    }

}


