import { BrowserRouter as Router, Routes, Route, useParams, Navigate, } from "react-router-dom";
import { useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import Layout from '../Layout/Layout';
import History from '../Helpers/History';
import HomePage from "../Webpages/HomePage/index.js";
import SavedNotes from "../Webpages/SavedNotes/SavedNotes.js";
import Webinar from "../Webpages/Webinar/Webinar.js";
import BattleBandsNaryana from "../Webpages/BattleOfBandsNarayana/BattleBandsNaryana.jsx";
import Premium from "../Webpages/SchoolPremium/Premium.jsx";
import MembershipPlanCancel from "../Webpages/Membership/MembershipPlanCancel/MembershipPlanCancel.jsx";
import MembershipCancelReason from "../Webpages/Membership/MembershipPlanCancel/MembershipCancelReason.jsx";
const Referal = lazy(() => import("../Webpages/Referal/Referal.js"));
const MyReferals = lazy(() => import("../Webpages/Referal/MyReferals/MyReferal.js"));
const BattleBands = lazy(() => import("../Webpages/BattleOfBands/BattleOfBands"));
const FanPageListing = lazy(() => import("../Webpages/BattleOfBands/FanPage/FanPageListing.js"));
const FanPage = lazy(() => import("../Webpages/BattleOfBands/FanPage/FanPage.js"));
const EmailVerificationBob = lazy(() => import("../Webpages/Authentication/Register/EmailVerification/EmailVerificationBob.js"));
const WhatsappLogin = lazy(() => import("../Webpages/Authentication/WhatsappLogin/WhatsappLogin.js"));
const WhatsappRegister = lazy(() => import("../Webpages/Authentication/WhatsappLogin/WhatsappRegister.js"));
const GiftCards = lazy(() => import("../Webpages/GiftCards/GiftCards.js"));

// lazy loading
const Login = lazy(() => import('../Webpages/Authentication/Login/Login'));
const ForgotPassword = lazy(() => import('../Webpages/Authentication/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('../Webpages/Authentication/ResetPassword/ResetPassword'));
const EmailVerification = lazy(() => import('../Webpages/Authentication/Register/EmailVerification/EmailVerification'));
const PrivateRoute = lazy(() => import('./PrivateRoute/PrivateRoute'));
const Register = lazy(() => import('../Webpages/Authentication/Register/Register'));
const InstructorDetails = lazy(() => import('../Webpages/Instructor/InstructorDetails'));
const Instructors = lazy(() => import('../Webpages/Instructor/Instructors'));
const Songs = lazy(() => import('../Webpages/Songs/Songs'));
const SongDetail = lazy(() => import('../Webpages/Songs/SongDetail'));
const Faq = lazy(() => import('../Webpages/ContactUs/Faq'));
const ContactUs = lazy(() => import('../Webpages/ContactUs/ContactUs'));
const Notification = lazy(() => import('../Webpages/Profile/Notification'));
const Membership = lazy(() => import('../Webpages/Profile/Membership'));
const Courses = lazy(() => import('../Webpages/Courses/Courses'));
const CourseView = lazy(() => import('../Webpages/Courses/CourseView'));
const Dashboard = lazy(() => import('../Webpages/Dashboard/Dashboard'));
const SavedCourses = lazy(() => import('../Webpages/SavedCourses/SavedCourses'));
const FeedbackLibrary = lazy(() => import('../Webpages/FeedbackLibrary/FeedbackLibrary'));
const ProfileSetting = lazy(() => import('../Webpages/Profile/ProfileSetting'));
const AboutUs = lazy(() => import('../Webpages/AboutUs/AboutUs'));
const MembershipPlans = lazy(() => import('../Webpages/Membership/MembershipPlans'));
const Payment = lazy(() => import('../Webpages/Membership/Payment/Payment'));
const PaymentSuccess = lazy(() => import('../Webpages/Membership/Payment/PaymentSuccess'));
const PaymentCancel = lazy(() => import('../Webpages/Membership/Payment/PaymentCancel'));
const NotFound = lazy(() => import('../components/Common/NotFound/NotFound'));
const VerifyEmail = lazy(() => import('../Webpages/Authentication/Register/VerifyEmail'));
const MembershipCancel = lazy(() => import('../Webpages/Membership/MembershipCancel'));
const MembershipSuccess = lazy(() => import('../Webpages/Membership/MembershipSuccess'));
const Blogs = lazy(() => import('../Webpages/Blogs/Blogs'));
const BlogDetails = lazy(() => import('../Webpages/Blogs/BlogDetails'));
const TC = lazy(() => import('../Webpages/T&C/TC'));
const PaymentPending = lazy(() => import('../Webpages/Membership/Payment/PaymentPending'));

function RouteIndex() {
    const authState = useSelector((state) => state.auth);


    useEffect(() => {
        const unlisten = History.listen((location, action) => {
            if (History.action === 'POP') {
                document.querySelector('.modal-backdrop')?.classList?.remove('modal-backdrop');
                if (History.action === 'POP') {
                    document.querySelector('.modal-backdrop')?.remove();
                    document.body.style.removeProperty("overflow");
                    document.body.classList.remove("modal-open");

                }
            }
        });

        return unlisten;

    }, [])

    return (
        <Router history={History}>
            <Suspense fallback={<div style={{ paddingTop: "19%", minHeight: "800px" }}>
                <div
                    className="loader spinner-border m-5 d-table m-auto"
                    role="status"
                >
                    <span className="visually-hidden"></span>
                </div>
                <span className=" m-5 d-table m-auto">Loading...</span>
            </div>}>

                <Layout>
                    <Routes>
                        <Route exact path="/" element={<HomePage />} />
                        <Route exact path="/signin" element={<Login />} />
                        {/* for whatsapp */}
                        <Route exact path="/signin-whatsapp" element={<WhatsappLogin />} />
                        <Route exact path="/signup-whatsapp" element={<WhatsappRegister />} />

                        <Route exact path="/email-verification/:token" element={<EmailVerification />} />
                        {/* <Route exact path="/guitar-tuner" element={<GuitarTuner />} />
                        <Route exact path="/practice-chords" element={<GuitarChords />} />
                        <Route exact path="/chords" element={<GuitarChords2 />} />
                        <Route exact path="/metronome" element={<Metronom />} />
                        <Route exact path="/practice-scales" element={<GuitarScales />} /> */}
                        <Route exact path="/instructors/:instructorHash" element={<InstructorDetails />} />
                        <Route exact path="/:courses-lessons/:courseSlug" element={<CourseView viewPage={''} />} />

                        <Route exact path="/:courses-courses/:courseSlug" element={
                            // <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}>
                                <CourseView viewPage={'course'} />
                            // </PrivateRoute>
                        } />
                        <Route path="/:courses-lessons" element={<Courses />} />

                        <Route path="/:songplaylist-songs-playlist/:songsHash" element={<SongDetail />} />

                        <Route exact path="/instructors" element={<Instructors />} />
                        <Route exact path="/about-us" element={<AboutUs />} />
                        <Route exact path="/membership" element={<MembershipPlans />} />
                        <Route exact path="/membership/:id" element={<Payment />} />
                        <Route exact path="/webinar" element={<Webinar />} />

                        {/* <Route exact path="/:songs-songs/:songId" element={<Songs />} /> */}

                        <Route exact path="/t&c" element={<TC />} />
                        <Route exact path="/faq" element={<Faq />} />
                        <Route exact path="/support" element={<ContactUs />} />
                        {/* <Route exact path="/new-details-page" element={<NewCourseDetails />} /> */}

                        <Route path="/resetpassword" element={
                            <PrivateRoute isSignedIn={authState?.forgotPasswordFlag || authState.sendCode}><ResetPassword /></PrivateRoute>
                        } />
                        <Route path="/forgotpassword" element={
                            <PrivateRoute isSignedIn={!authState?.userDetails?.hash || !authState?.userDetails?.token}><ForgotPassword /></PrivateRoute>
                        } />
                        <Route exact path="/signup" element={
                            <PrivateRoute isSignedIn={!authState?.userDetails?.hash || !authState?.userDetails?.token}><Register /></PrivateRoute>
                        } />

                        {/* with referral code */}
                        {/* <Route exact path="/signup/:refCode" element={
                            <PrivateRoute isSignedIn={!authState?.userDetails?.hash || !authState?.userDetails?.token}><Register /></PrivateRoute>
                        } /> */}

                        <Route path="/verify-email" element={
                            <PrivateRoute isSignedIn={!authState?.userDetails?.hash || !authState?.userDetails?.token}><VerifyEmail /></PrivateRoute>
                        } />
                        <Route path="/profile-setting" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><ProfileSetting /></PrivateRoute>
                        } />
                        <Route path="/notification" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><Notification /></PrivateRoute>
                        } />

                        <Route path="/referral" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><Referal /></PrivateRoute>
                        } />
                        <Route path="/my-referrals" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><MyReferals /></PrivateRoute>
                        } />


                        <Route path="/membership-settings" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><Membership /></PrivateRoute>
                        } />

                        <Route path="/dashboard" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><Dashboard /></PrivateRoute>
                        } />

                        <Route path="/saved-courses" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><SavedCourses /></PrivateRoute>
                        } />

                        <Route path="/feedback-library" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><FeedbackLibrary /></PrivateRoute>
                        } />
                        <Route path="/saved-notes" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><SavedNotes /></PrivateRoute>
                        } />

                        {/* Personalization course routes */}
                    

                        <Route path="/membership/success" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><PaymentSuccess /></PrivateRoute>
                        } />

                        <Route path="/membership/failure" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><PaymentCancel /></PrivateRoute>
                        } />

                        <Route path="/cancel/failure" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><MembershipCancel /></PrivateRoute>
                        } />


                        <Route path="/membership/cancel" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><MembershipPlanCancel /></PrivateRoute>
                        } />

                        <Route path="/membership/cancel/reasons" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><MembershipCancelReason /></PrivateRoute>
                        } />

                        <Route path="/cancel/success" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><MembershipSuccess /></PrivateRoute>
                        } />

                        <Route path="/membership/pending" element={
                            <PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><PaymentPending /></PrivateRoute>
                        } />

                        <Route exact path="/blogs" element={<Blogs />} />
                        <Route path="/blogs/:blogsId" element={<BlogDetails />} />


                        <Route exact path="/premium" element={<Premium />} />


                        {/* for event */}

                        <Route path="/battle-of-bands" element={<BattleBands />} />
                        <Route path="/battle-of-bands/fanpage" element={<FanPageListing />} />
                        <Route path="/battle-of-bands/fanpage/:id" element={<FanPage />} />
                        <Route path="/battle-of-bands/fanpage/email-verification/:token" element={<EmailVerificationBob />} />
                        <Route path="/battle-of-bands/narayana" element={<BattleBandsNaryana />} />
                        <Route path="/giftCards" element={<GiftCards />} />
                        <Route path="/giftcard/success" element={<PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><GiftCards /></PrivateRoute>} />
                        <Route path="/giftCards/:id" element={<PrivateRoute isSignedIn={authState?.userDetails?.hash || authState?.userDetails?.token}><GiftCards /></PrivateRoute>} />
                        <Route exact path="/redeem/:id" element={<Payment isGiftCard={true} />} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="/blog" element={<NotFound />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />

                    </Routes>
                </Layout>
                {/* <NotFound /> */}
            </Suspense>

        </Router>
    )
}
export default RouteIndex
